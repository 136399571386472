<!--
 * @Author: SongYijie
 * @Date: 2020-03-13 10:27:18
 * @LastEditors: SongYijie
-->
<template>
  <div class='face-upload'>
    <div class="sketch">
      <img src="@assets/images/individualBusiness/sketch.png" alt="sketch" />
      <p class="sketch-text">正面平视手机、保证光线充足<br />请勿遮挡面部</p>
    </div>
    <div class="loading-wrap" v-show="isUploading">
      <img src="@assets/images/individualBusiness/loading.gif" alt="loading" class="loading" />
    </div>
    <div class='footer-bt'>
      <mt-button type='danger' size='large'>
        人脸验证
        <input type="file" accept="image/*" id="camera" @change="throttleHandleChange" capture="user" />
      </mt-button>
    </div>
    
  </div>
</template>

<script>
  import { throttle } from "@utils/common";
  import { facePicCheck } from "@api/individualBusiness";
  import { photoCompress } from '@utils/uploadImg';
  import { Toast } from 'mint-ui';
  export default {
    name: 'faceUpload',
    components: {},
    data() {
      return {
        isUploading: false
      }
    },
    computed: {},
    watch: {},
    mounted() {},
    methods: {
      throttleHandleChange: throttle(
        function(e) {
          this.handleChange(e);
        }, 2000
      ),
      handleChange(e) {
        const file = e.target.files[0];
        if (!file) return;
        this.isUploading = true;
        setTimeout(() => {
          this.isUploading = false;
        }, 20000);
        const self = this;
        const size = file.size / 1024;
        if (size > 300) {
          photoCompress(file, {
            quality: 0.6,
            zoom: 0.4
          }, blobFile => {
            this.handleUploadFile(blobFile);
          });
        } else {
          this.handleUploadFile(file);
        }
      },
      handleUploadFile(file) {
        let imageFormData = new FormData();
        imageFormData.append("file", file);
        facePicCheck(imageFormData).then(res => {
          this.isUploading = false;
          if (res.code === 200) {
            this.$router.replace('/individual-business/confirm-info');
          } else {
            Toast(res.message);
          }
        })
      }
    }
  };
</script>

<style lang='less' scoped>
#camera {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  cursor: pointer;
  z-index: 2;
}
.sketch {
  background: hsla(0,0%,88%,.35);
  padding: .8333rem 1.666rem 1.2rem;
  text-align: center;
  > img {
    width: 14.916rem;
    height: 21.583rem;
  }
  .sketch-text {
    font-size: 14px;
    color: #999;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
  }
}
.face-upload {
  position: relative;
}
.loading-wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(120, 120, 120, 0.4);
  z-index: 2;
}
.loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>